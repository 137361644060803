import request from '@/api/request'

export function siteItem(data) {
    return request({url: '/site/common/item', method: 'post', data})
}

export function cateTree(data) {    // 文章分类树形
    return request({url: '/cate/common/tree', method: 'post', data})
}

export function updateRecommend(data) {
    return request({url: '/site/updateRecommend', method: 'post', data})
}

export function updateBanner(data) {
    return request({url: '/site/updateBanner', method: 'post', data})
}

export function articlePage(data) {   // 文章分页
    return request({url: '/article/common/page', method: 'post', data})
}

export function articleItem(data) {   // 文章单条
    return request({url: '/article/common/item', method: 'post', data})
}

export function indexData(data) {   // 主页
    return request({url: '/index/common/index', method: 'post', data})
}


export function cateArticle(data) {   // 一级分类下的文章
    return request({url: '/article/common/cateArticle', method: 'post', data})
}

export function getBanner(data) {   // banner
    return request({url: '/banner/findAll', method: 'post', data})
}

/*活动*/
export function findAllActByPage(data) {   // 查询所有活动信息
    return request({url: `/activity/findAllByPage`, method: 'post', data})
}
export function getAllActById(data) {   // 查询活动信息通过活动ID
    return request({url: `/activity/findAllById`, method: 'post', data})
}
export function findActAndComByAct(data) {   // 查询单位和岗位信息通过活动ID
    return request({url: `/activity/findActAndComByAct`, method: 'post', data})
}

/*单位*/
export function findComAndPostByCom(data) {   // 查询单位和岗位信息通过单位ID
    return request({url: `/company/findComAndPostByCom`, method: 'post', data})
}
export function findComId() {   // 查询单位信息通过单位ID
    return request({url: `/company/getId`, method: 'get'})
}
export function getCompanies() {   // company
    return request({url: '/company/findAll', method: 'get'})
}
export function getCompaniesByActId(data) {   // 查询所有单位通过活动ID
    return request({url: `/company/getComsByActId`, method: 'post', data})
}

/*岗位*/
export function getPostByComId(data) {   // 查询单位和岗位信息根据单位ID
    return request({url: `/post/getPostByComId`, method: 'post', data})
}
export function findPostById(data) {   // 根据单位id获取单位和岗位信息
    return request({url: `/post/findPostById`, method: 'post', data})
}
export function getPostListByComId(data) {   // 查询岗位列表通过单位ID
    return request({url: `/post/getPostListByComId`, method: 'post', data})
}
export function findAllPost(data) {   // 查询所有岗位信息
    return request({url: `/post/findAllPost`, method: 'post', data})
}

/*猎头*/
export function findHeadHuntingAndComByAct(data) {   // 查询单位信息
    return request({url: `/headhunting/findHeadHuntingAndComByAct`, method: 'post', data})
}
export function findComAndPostById(data) {   // 查询单位和岗位信息
    return request({url: `/headhunting/findComAndPostById`, method: 'post', data})
}



