<template>
    <div class="company-container">
        <div class="company-main">
            <div class="company-header">
                    <el-carousel arrow="always">
                        <el-carousel-item :key="index" v-for="(item,index) in headImg">
                           <img style="width: 100%;height: 100%" :src="item.img" alt="">
                        </el-carousel-item>
                    </el-carousel>
            </div>
            <div class="company-body">
                <div class="company-body-count">
                    <div class="company-body-count-company">
                        <p class="company-countText">{{companyNum}}</p>
                        <p class="company-wordText">参与单位</p>
                    </div>
                    <div class="company-body-count-post">
                        <p class="company-countText">{{postNum}}</p>
                        <p class="company-wordText">招聘职位</p>
                    </div>
                </div>
                <div class="headhunting-upResume" @click="handleDialogVisible">
                    <img src="../assets/images/headhunting-upload.jpg" style="width: 100%;height: 100%" alt=""/>
                </div>
                <Dialog :dialogVisible = 'dialogVisible' :activityName = 'headhuntingName' :companyName = 'companyName' :postName = 'postName'  @sendDialogVisible = 'getDialogVisible'></Dialog>
                <div class="company-bodyCard">
                    <div class="company-body-tab">单位大厅</div>
                    <div class="company-body-card" >
                        <div class="company-body-cardDetail" v-for="company in companys">
                            <el-card class="box-card" :body-style="{padding: '0 20px'}">
                                <div slot="header" class="clearfix">
                                    <router-link :to="{name:'companyDetail',query:{headhuntingId:headhuntingId,id:company.id,tab:'tab1'}}" class="company-card-head">
                                        <div class="company-card-headWord">
                                            <div class="company-card-headWord-name">{{company.name}}</div>
                                            <div class="company-card-headWord-content">
                                                <span class="company-card-headWord-contentDetail">{{company.city}}</span>
                                                <!--                                                <span class="company-card-headWord-contentDetail">500-999人</span>-->
                                                <span class="company-card-headWord-contentDetails">{{company.companyType}}</span>
                                            </div>
                                        </div>
                                        <div class="company-card-headImg">
                                            <img :src="company.images" style="width: 100%;height: 100%" alt=""/>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="company-card-bodyPost">
                                    <div class="company-card-bodyPostItem"  v-for="post in company.postList">
                                        <router-link :to="{name:'post',query: {headhuntingId:headhuntingId.id,postId:post.id,companyId:company.id}}" class="card-job-name">
                                            <span class="job_name">{{post.post}}</span>
                                            <span class="job_salary">{{post.salary}}</span>
                                        </router-link>
                                        <div class="act-section-left">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-deal">
                                    <router-link :to="{name:'companyDetail',query:{headhuntingId:headhuntingId.id,id:company.id,tab:'tab1'}}">
                                    <span class="card-deal-more">更多职位
                                        <i class="el-icon-arrow-right" />
                                    </span>
                                    </router-link>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
            </div>
            <div class="company-footer"></div>
        </div>
    </div>
</template>

<script>
    import * as api from '@/api/api'
    import Dialog from '@/views/dialog'
    export default {
        name: "headhunting",
        components: {
            Dialog
        },
        data() {
            return {
                headhuntingId:1,
                headhunting: [],
                headhuntingName:'',
                companys:[],
                companyName:'未指定单位',
                postName:'未指定岗位',
                companyId:[],
                dialogVisible: false,
                companyNum:'',
                postNum:'',
                headImg:[
                    {img:"https://xycy-file.zhaopin.com/xyactivity/2023/8/1/15/59/b14f6b4973a848dea3f09dc13c941f3b_.jpg"},
                    {img:"https://xycy-file.zhaopin.com/xyactivity/2023/8/1/15/58/9361ddb07de743df8232cca0bc416c8a_.jpg"},
                ]
            }
        },
        methods: {
            //查询猎头信息
            getHeadHuntingAndComByAct() {
                api.findHeadHuntingAndComByAct({id:this.headhuntingId}).then(res => {
                    this.headhunting = res
                    this.headhuntingName = res.name
                    this.companyNum = res.companyList.length
                })
            },

            //查询岗位信息
            getPost() {
                api.findComAndPostById({id:this.headhuntingId}).then(res => {
                    this.companys = res
                    console.log()
                    let postNum = 0
                    for(let i = 0; i < res.length; i++) {
                        let num = res[i].postList.length
                        postNum += num
                    }
                    this.postNum = postNum
                })
            },
            handleDialogVisible() {
                this.dialogVisible = !this.dialogVisible
            },
            getDialogVisible(e) {
                this.dialogVisible = e
            }
        },
        created() {
            this.getHeadHuntingAndComByAct()
            this.getPost()
        }
    }
</script>

<style scoped>
    @media screen and (min-width: 991px){
        .el-carousel /deep/ .el-carousel__container {
            height: 360px;
        }
        ::v-deep .el-dialog {
            position: relative;
            margin: 0 auto;
            background: #FFF;
            border-radius: 2px;
            box-shadow: 0 1px 1px white;
            box-sizing: border-box;
            width: 30vw;
        }
        .headhunting-upResume {
            width: 64%;
            margin: 0 auto;
            cursor: pointer;
        }
        .company-container {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
        .company-main {
            padding-bottom: 80px;
        }
        .company-header {
            width: 100%;
            height: 360px;
        }
        .company-body-count {
            display: flex;
            background: white;
            width: 100%;
            height: 86px;
            justify-content: center;
            align-items: center;
        }
        .company-body-count-company {
            width: 30%;
            text-align: center;
        }
        .company-body-count-post {
            width: 30%;
            text-align: center;
        }
        .company-countText {
            height: 38px;
            font-size: 32px;
            font-weight: 500;
            color: #fb6160;
            line-height: 38px;
        }
        .company-wordText {
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            color: #7a7f99;
            font-weight: 400;
            line-height: 22px;
            height: 22px;
            margin-top: 2px;
        }
        .company-body-tab {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #141933;
            margin: 40px 0;
        }
        .act-section-left span {
            background: url('../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }
        .company-bodyCard {
            width: 64%;
            margin: 32px auto 16px auto;
        }
        /*卡片*/
        .company-body-card {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .company-body-cardDetail {
            height: auto;
            overflow: hidden;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 0 0 10px 0;
        }
        .company-card-headImg {
            width: 36px;
            height: 36px;
            border-radius: 4px;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: pointer;
        }
        /*卡片头部*/
        ::v-deep .el-card__header {
            background-color: #2A3F76;
        }
        .company-card-head {
            height: 40px;
            /*background: #fff;*/
            background-color: #2A3F76;
            border-radius: 4px 4px 0 0;
            align-items: center;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }
        .company-card-headWord {

        }
        .company-card-headWord-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .company-card-headWord-contentDetail {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            /*color: #7a7f99;*/
            color: white;
            line-height: 18px;
        }
        .company-card-headWord-contentDetails {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            /*color: #7a7f99;*/
            color: white;
            line-height: 18px;
        }
        .company-card-headWord-contentDetail:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
            -webkit-box-align: center;
        }
        .company-card-headWord-name {
            cursor: pointer;
            letter-spacing: 0;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            /*color: #141933;*/
            color: white;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 297px;
        }
        .company-card-bodyPost {
            height: 114px;
            margin-top: 16px;
            overflow: hidden;
        }
        .company-card-bodyPostItem {
            height: 26px;
            margin-bottom: 12px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .card-job-name {
            cursor: pointer;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #333;
            line-height: 14px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .job_name {
            display: inline-block;
            max-width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .job_salary {
            margin-left: 8px;
            display: inline-block;
            max-width: 200px;
            /*72px*/
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .delivery-work {
            text-align: center;
            width: 56px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            cursor: pointer;
            background: #f8f9fc;
            border-radius: 12px;
            border: 1px solid #e6e8f2;
            color: #b8bbcc;
            background: #fff;
            border: 1px solid #587cf7;
            color: #587cf7;
        }
        .card-deal {
            overflow: hidden;
            /*bottom: 12px;*/
            position: relative;
            float: right;
        }
        .card-deal-more {
            cursor: pointer;
            height: 30px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 30px;

        }
        .el-icon-arrow-right {
            font-family: element-icons !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: baseline;
            display: inline-block;
            -webkit-font-smoothing: antialiased;
        }
        /*<!--    卡片属性-->*/
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }
        .clearfix:after {
            clear: both
        }
        .box-card {
            width: 389px;
            height: 248px;
        }
    }

    /*手机*/
    @media screen and (max-width: 991px){
        .el-carousel /deep/ .el-carousel__container {
            height: 132px;
        }
        .company-container {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
        .company-main {
            padding-bottom: 80px;
        }
        /*.company-header {*/
        /*    width: 100%;*/
        /*    height: 70vh;*/
        /*}*/
        .company-body-count {
            display: flex;
            background: white;
            width: 100%;
            height: 86px;
            justify-content: center;
            align-items: center;
        }
        .company-body-count-company {
            width: 30%;
            text-align: center;
        }
        .company-body-count-post {
            width: 30%;
            text-align: center;
        }
        .company-countText {
            height: 38px;
            font-size: 32px;
            font-weight: 500;
            color: #fb6160;
            line-height: 38px;
        }
        .company-wordText {
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            color: #7a7f99;
            font-weight: 400;
            line-height: 22px;
            height: 22px;
            margin-top: 2px;
        }
        .company-body-tab {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #141933;
            margin: 40px 0;
        }
        .act-section-left span {
            background: url('../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }
        .company-bodyCard {
            /*width: 64%;*/
            margin: 32px auto 16px auto;
        }
        /*卡片*/
        .company-body-card {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        .company-body-cardDetail {
            height: auto;
            overflow: hidden;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 0 0 10px 0;
        }
        .company-card-headImg {
            width: 36px;
            height: 36px;
            border-radius: 4px;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: pointer;
        }
        /*卡片头部*/
        ::v-deep .el-card__header {
            background-color: #2A3F76;
        }
        .company-card-head {
            height: 40px;
            /*background: #fff;*/
            background-color: #2A3F76;
            border-radius: 4px 4px 0 0;
            align-items: center;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }
        .company-card-headWord-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .company-card-headWord-contentDetail {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            /*color: #7a7f99;*/
            color: white;
            line-height: 18px;
        }
        .company-card-headWord-contentDetails {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            /*color: #7a7f99;*/
            color: white;
            line-height: 18px;
        }
        .company-card-headWord-contentDetail:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
            -webkit-box-align: center;
        }
        .company-card-headWord-name {
            cursor: pointer;
            letter-spacing: 0;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            /*color: #141933;*/
            color: white;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 297px;
        }
        .company-card-bodyPost {
            height: 114px;
            margin-top: 16px;
            overflow: hidden;
        }
        .company-card-bodyPostItem {
            height: 26px;
            margin-bottom: 12px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .card-job-name {
            cursor: pointer;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #333;
            line-height: 14px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .job_name {
            display: inline-block;
            max-width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .job_salary {
            margin-left: 8px;
            display: inline-block;
            max-width: 200px;
            /*72px*/
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .delivery-work {
            text-align: center;
            width: 56px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            cursor: pointer;
            background: #f8f9fc;
            border-radius: 12px;
            border: 1px solid #e6e8f2;
            color: #b8bbcc;
            background: #fff;
            border: 1px solid #587cf7;
            color: #587cf7;
        }
        .card-deal {
            overflow: hidden;
            /*bottom: 12px;*/
            position: relative;
            float: right;
        }
        .card-deal-more {
            cursor: pointer;
            height: 30px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 30px;

        }
        .el-icon-arrow-right {
            font-family: element-icons !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: baseline;
            display: inline-block;
            -webkit-font-smoothing: antialiased;
        }
        /*<!--    卡片属性-->*/
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }
        .clearfix:after {
            clear: both
        }
        .box-card {
            width: 389px;
            height: 248px;
        }
    }

</style>

