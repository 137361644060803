<template>
    <div id="companyDetail">
        <div class="companyDetail-main">
            <div class="companyDetail-header" v-if="activityImages != null">
                <img alt="" :src=activityImages style="width: 100%;height: 100%"/>
            </div>
            <div class="companyDetail-head">
                <div class="companyDetail-card-head">
<!--                    <div class="companyDetail-card-headImg">-->
<!--                        <img :src="companyInfo.images" style="width: 100%;height: 100%"/>-->
<!--                    </div>-->
                    <div class="companyDetail-card-headWord">
                        <div class="companyDetail-card-headWord-name" >
                            {{companyInfo.name}}</div>
                        <div class="companyDetail-card-headWord-content">
                            <span class="companyDetail-card-headWord-contentDetail">{{companyInfo.city}}</span>
<!--                            <span class="companyDetail-card-headWord-contentDetail">500-999人</span>-->
<!--                            <span class="companyDetail-card-headWord-contentDetail">民营</span>-->
<!--                            <span class="companyDetail-card-headWord-contentDetails">学校/学历教育</span>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="companyDetail-tab">
                <div class="companyDetail-tab1">
                    <div :style="tabActive1" class="companyDetail-tab-item"  @click="changeTab1">招聘职位·{{postList.length}}</div>
                    <div :style="tabActive2" class="companyDetail-tab-item" @click="changeTab2">企业详情</div>
                </div>
            </div>
            <div class="companyDetail-body1" v-if="tab === 'tab1'">
                <div v-for="post in postList">
                    <router-link :to="{name:'post',query:{activityId:activity.id,postId:post.id,companyId:companyInfo.id}}">
                        <div class="companyDetail-body1-card" >
                            <div class="companyDetail-body1-cardTop">
                                <div class="companyDetail-body1-cardTop-left">
                                    <div class="companyDetail-body1-cardTop-left-company">
                                        <span class="companyDetail-body1-cardTop-left-companyName">{{post.post}}</span>
                                        <span class="companyDetail-body1-cardTop-left-companySalary">{{post.salary}}</span>
                                    </div>
                                    <div class="companyDetail-body1-cardTop-left-tag">
                                        <span class="companyDetail-body1-cardTop-left-tagWord">{{post.workPlace}}</span>
                                        <span class="companyDetail-body1-cardTop-left-tagWord">{{post.edu}}</span>
                                        <span class="companyDetail-body1-cardTop-left-tagWord">{{post.people}}人</span>
                                        <span class="companyDetail-body1-cardTop-left-tagWords">全职</span>
                                    </div>
                                </div>
                                <div class="act-section-left">
                                    <span></span>
                                </div>
                            </div>
                            <div class="companyDetail-body1-cardPostDesc">
                                <span class="companyDetail-body1-cardPostDesc-title">岗位职责</span>
                                <div class="companyDetail-body1-cardPostDesc-content">
                                    {{post.postDuty}}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="companyDetail-introduce" v-if="tab === 'tab2'">
                <div class="companyDetail-introduce-left">
                    <div class="companyDetail-introduce-left-company">
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>单位介绍</h3>
                            <div class="companyDetail-introduce-left-companyText">
                              <div class="companyDetail-introduce-left-companyText-text companyDetail-introduce-left-companyText-ellipsis">
                                <p>{{companyInfo.introduction}}</p>
                              </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="companyDetail-introduce-left-company" >-->
<!--                        <div class="companyDetail-introduce-left-companyTitle">-->
<!--                            <h3>单位相册</h3>-->
<!--                            <div class="companyDetail-introduce-left-companyText">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="companyDetail-introduce-left-company" >
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>单位福利</h3>
                            <div class="companyDetail-introduce-left-companyText">
                                <div class="companyDetail-introduce-left-companyText-text companyDetail-introduce-left-companyText-ellipsis">
                                    <p>{{postList[0].otherWelfare}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="companyDetail-introduce-right">
                    <div class="companyDetail-introduce-left-company">
                        <div class="companyDetail-introduce-left-companyTitle">
                            <h3>单位地址</h3>
                            <div class="companyDetail-introduce-left-companyText-text">
                                <p>{{postList[0].workPlace}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="companyDetail-footer">
            </div>

        </div>
    </div>
</template>

<script>
    import * as api from '@/api/api'
    export default {
        name: "companyDetail",
        data() {
            return {
                headhuntingId:this.$route.query.headhuntingId,
                activityId:this.$route.query.activityId,
                activity: [],
                activityImages:'',
                companyId: this.$route.query.id,
                companyInfo: [],
                postList:[],
                tab:this.$route.query.tab,
                tabActive1:'',
                tabActive2:''
            }
        },
        created() {
            this.findActAndCompById()
            this.findCompanyInfo()
            this.tabStyle()
        },
        methods: {
            //查询活动信息
            findActAndCompById() {
                if (this.activityId != null){
                    api.findActAndComByAct({id:this.activityId}).then(res => {
                        this.activity = res
                        this.activityImages = res.activityImages
                    })
                } else {
                    this.activityImages = null
                }
            },
            findCompanyInfo() {
                let req = {}
                if(this.headhuntingId) {
                    req = {
                        id:this.companyId,
                        activityId: this.activityId
                    }
                }
                if(!this.headhuntingId) {
                    req = {
                        id:this.companyId,
                        activityId: this.activityId,

                    }
                }
                api.findComAndPostByCom(req).then(res => {
                    this.companyInfo = res
                    this.postList = res.postList
                    console.log(this.postList)
                })
            },
            changeTab1() {
                this.tab = "tab1"
                this.tabActive2 = ''
                this.tabActive1 = {fontSize: '20px',
                    fontWeight: '600',
                    color: '#141933'}

            },
            changeTab2() {
                this.tab = "tab2"
                this.tabActive1 = ''
                this.tabActive2 = {fontSize: '20px',
                    fontWeight: '600',
                    color: '#141933'}
            },
            tabStyle() {
                if(this.$route.query.tab === 'tab1') {
                    this.tabActive1 = {fontSize: '20px',
                        fontWeight: '600',
                        color: '#141933'}
                }
                if(this.$route.query.tab === 'tab2') {
                    this.tabActive2 = {fontSize: '20px',
                        fontWeight: '600',
                        color: '#141933'}
                }
            }
        }
    }
</script>

<style scoped>
    @media screen and (min-width: 991px){
        #companyDetail {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
        .companyDetail-main {
            width: 100%;
            margin: 0 auto 16px;
            padding-bottom: 80px;
        }
        .companyDetail-header {
            width: 100%;
            height: 70vh;
        }
        .companyDetail-head {
            width: 100%;
            background: #fff;
            margin: 0 auto;
            border-top: #F5F5F5 2px solid;
        }
        .companyDetail-card-head {
            width: 64%;
            margin: 0 auto;
            height: 126px;
            -webkit-box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: flex-start;
        }
        .companyDetail-card-headWord {

        }
        .act-section-left span {
            background: url('../../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }
        .companyDetail-card-headWord-name {
            height: 32px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 32px;
        }
        .companyDetail-card-headWord-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            margin-top: 12px;
        }
        .companyDetail-card-headWord-contentDetail {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headWord-contentDetail:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
            -webkit-box-align: center;
        }
        .companyDetail-card-headWord-contentDetails {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headImg {
            width: 78px;
            height: 78px;
            border-radius: 4px;
            margin-right: 20px;
        }
        .companyDetail-tab {
            width: 64%;
            margin: 0 auto;
        }
        .companyDetail-tab1 {
            height: 32px;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: flex-start;
            margin-top: 16px;
        }
        .companyDetail-tab-item {
            height: 32px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 32px;
            margin-right: 32px;
        }
        .companyDetail-body1 {
            width: 64%;
            margin: 0 auto;
        }
        .companyDetail-introduce {
            width: 64%;
            margin: 0 auto;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
        }
        .companyDetail-introduce-left {
            width: 864px;
        }
        .companyDetail-introduce-right {
            width: 320px;
        }


        .companyDetail-introduce-left-company {
            margin-top: 16px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
            padding: 20px 40px 40px;
        }
        .companyDetail-introduce-left-companyTitle {

        }
        .companyDetail-introduce-left-companyTitle h3 {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 22px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .companyDetail-introduce-left-companyTitle h3:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 16px;
            background: #141933;
            border-radius: 0 2px 2px 0;
            margin-right: 8px;
        }
        .companyDetail-introduce-left-companyText {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .companyDetail-introduce-left-companyText-text {
            margin-top: 24px;
            text-align: justify;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 24px;
            word-break: break-all;
        }
        .companyDetail-introduce-left-companyText-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /*-webkit-line-clamp: 6;*/
            /*line-clamp: 6;*/
            -webkit-box-orient: vertical;
        }
        .companyDetail-body1-card {
            padding: 0 20px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
            margin-top: 16px;
            cursor: pointer;
        }
        .companyDetail-body1-cardTop {
            height: 88px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }
        .companyDetail-body1-cardTop-left {

        }
        .companyDetail-body1-cardTop-left-company {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .companyDetail-body1-cardTop-left-companyName {
            color: #141933;
            display: inline-block;
            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 16px;
            cursor: pointer;
        }
        .companyDetail-body1-cardTop-left-companySalary {
            color: #fb6160;
            width: 750px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .companyDetail-body1-cardTop-left-tag {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-top: 8px;
        }
        .companyDetail-body1-cardTop-left-tagWord {
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            display: inline-block;
        }
        .companyDetail-body1-cardTop-left-tagWord:after {
            content: "";
            display: inline-block;
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
        }
        .companyDetail-body1-cardTop-left-tagWords {
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            display: inline-block;
        }
        .deliver-btn {
            width: 120px;
            height: 40px;
            background: #587cf7;
        }
        .el-button--primary {
            color: #FFF;
            border-color: #409EFF;
        }
        .companyDetail-body1-cardPostDesc {
            border-top: 1px dashed #e6e8f2;
            padding: 15px 0 24px 0;
        }
        .companyDetail-body1-cardPostDesc-title {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 20px;
        }
        .companyDetail-body1-cardPostDesc-content {
            width: 60%;
            margin-top: 8px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: pointer;
            line-clamp: 2;
        }
    }
/*    小设备*/
    @media screen and (max-width: 991px){
        #companyDetail {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
        }
        .companyDetail-main {
            width: 100%;
            margin: 0 auto 16px;
            padding-bottom: 80px;
        }
        .companyDetail-head {
            width: 100%;
            background: #fff;
            margin: 0 auto;
        }
        .companyDetail-card-head {
            padding: 0 10px;
            width: 100%;
            margin: 0 auto;
            height: 126px;
            -webkit-box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 8px 0 rgba(20,25,51,.02);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: flex-start;
        }
        .companyDetail-card-headWord {

        }
        .act-section-left span {
            background: url('../../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }
        .companyDetail-card-headWord-name {
            height: 32px;
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 32px;
        }
        .companyDetail-card-headWord-content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            margin-top: 12px;
        }
        .companyDetail-card-headWord-contentDetail {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headWord-contentDetail:after {
            display: inline-block;
            content: "";
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
            -webkit-box-align: center;
        }
        .companyDetail-card-headWord-contentDetails {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
        }
        .companyDetail-card-headImg {
            width: 78px;
            height: 78px;
            border-radius: 4px;
            margin-right: 20px;
        }
        .companyDetail-tab {
            width: 100%;
            margin: 0 auto;
            padding: 0 10px;
        }
        .companyDetail-tab1 {
            height: 32px;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: flex-start;
            margin-top: 16px;
        }
        .companyDetail-tab-item {
            height: 32px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 32px;
            margin-right: 32px;
        }
        .companyDetail-body1 {
            width: 100%;
            margin: 0 auto;
        }
        /*.companyDetail-introduce {*/
        /*    width: 100%;*/
        /*    margin: 0 auto;*/
        /*    height: 100%;*/
        /*    display: -webkit-box;*/
        /*    display: -ms-flexbox;*/
        /*    display: flex;*/
        /*    -webkit-box-orient: horizontal;*/
        /*    -webkit-box-direction: normal;*/
        /*    -ms-flex-direction: row;*/
        /*    flex-direction: row;*/
        /*    -webkit-box-pack: justify;*/
        /*    -ms-flex-pack: justify;*/
        /*    justify-content: space-between;*/
        /*    -webkit-box-align: start;*/
        /*    -ms-flex-align: start;*/
        /*    align-items: flex-start;*/
        /*}*/
        /*.companyDetail-introduce-left {*/
        /*    width: 864px;*/
        /*}*/
        /*.companyDetail-introduce-right {*/
        /*    width: 320px;*/
        /*}*/


        .companyDetail-introduce-left-company {
            margin-top: 16px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
            padding: 20px 40px 40px;
        }
        .companyDetail-introduce-left-companyTitle {

        }
        .companyDetail-introduce-left-companyTitle h3 {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #141933;
            line-height: 22px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .companyDetail-introduce-left-companyTitle h3:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 16px;
            background: #141933;
            border-radius: 0 2px 2px 0;
            margin-right: 8px;
        }
        .companyDetail-introduce-left-companyText {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        .companyDetail-introduce-left-companyText-text {
            margin-top: 24px;
            text-align: justify;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 24px;
            word-break: break-all;
        }
        .companyDetail-introduce-left-companyText-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            /*-webkit-line-clamp: 6;*/
            /*line-clamp: 6;*/
            -webkit-box-orient: vertical;
        }
        .companyDetail-body1-card {
            padding: 0 20px;
            background: #fff;
            -webkit-box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            box-shadow: 0 2px 4px 0 rgba(20,25,51,.02);
            border-radius: 4px;
            margin-top: 16px;
            cursor: pointer;
        }
        .companyDetail-body1-cardTop {
            height: 88px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }
        .companyDetail-body1-cardTop-left {

        }
        .companyDetail-body1-cardTop-left-company {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        .companyDetail-body1-cardTop-left-companyName {
            color: #141933;
            display: inline-block;
            width: 96px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 16px;
            cursor: pointer;
        }
        .companyDetail-body1-cardTop-left-companySalary {
            color: #fb6160;
            width: 230px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .companyDetail-body1-cardTop-left-tag {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-top: 8px;
        }
        .companyDetail-body1-cardTop-left-tagWord {
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            display: inline-block;
        }
        .companyDetail-body1-cardTop-left-tagWord:after {
            content: "";
            display: inline-block;
            width: 1px;
            height: 10px;
            background: #e6e8f2;
            border-radius: 1px;
            margin-left: 8px;
            margin-right: 8px;
        }
        .companyDetail-body1-cardTop-left-tagWords {
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 18px;
            display: inline-block;
        }
        .deliver-btn {
            width: 120px;
            height: 40px;
            background: #587cf7;
        }
        .el-button--primary {
            color: #FFF;
            border-color: #409EFF;
        }
        .companyDetail-body1-cardPostDesc {
            border-top: 1px dashed #e6e8f2;
            padding: 15px 0 24px 0;
        }
        .companyDetail-body1-cardPostDesc-title {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #474c66;
            line-height: 20px;
        }
        .companyDetail-body1-cardPostDesc-content {
            width: 80%;
            margin-top: 8px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7a7f99;
            line-height: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            line-clamp: 1;
        }
    }
</style>
