import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import channel from '@/views/channel.vue'
import detail from "@/views/detail";
import about from "@/views/about";
import activity from "@/views/activity.vue";
import company from "@/views/company/index.vue";
import companyDetail from "@/views/company/detail.vue";
import post from "@/views/company/post.vue";
import headhunting from "@/views/headhunting.vue";


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        meta: {
            index: 1,      // 同样的标识
            keepAlive: true,
            content: '首页',
        }
    },
    {
        path: '/channel/:id',
        name: 'channel',
        component: channel,
        meta: {
            index: 2,      // 同样的标识
            keepAlive: true,
            content: '分类'
        }
    } ,{
        path: '/detail/:id',
        name: 'detail',
        component: detail,
        meta: {
            index: 3,      // 同样的标识
            keepAlive: true,
            content: '详情'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: about,
        meta: {
            index: 4,      // 同样的标识
            keepAlive: true,
            content: '关于我们'
        }
    },
    {
        path: '/activity',
        name: 'activity',
        component:activity,
        meta: {
            index: 5,      // 同样的标识
            keepAlive: true,
            content: '活动专区'
        }
    },
    {
        path: '/activity/company',
        name: 'company',
        component:company,
        meta: {
            index: 6,      // 同样的标识
            keepAlive: true,
            content: '单位'
        }
    },
    {
        path: '/activity/companyDetail',
        name: 'companyDetail',
        component:companyDetail,
        meta: {
            index: 7,      // 同样的标识
            keepAlive: true,
            content: '单位详情'
        }
    },
    {
        path: '/activity/post',
        name: 'post',
        component:post,
        meta: {
            index: 7,      // 同样的标识
            keepAlive: true,
            content: '岗位详情'
        }
    },
    {
        path: '/headhunting',
        name: 'headhunting',
        component:headhunting,
        meta: {
            index: 8,      // 同样的标识
            keepAlive: true,
            content: '猎头'
        }
    }
]

const router = new VueRouter({
    mode:'history',
    routes
})

// router.beforeEach((to, from, next) => {
//     /* 路由发生变化修改页面meta */
//     if (to.meta.content) {
//         document.title = to.meta.content
//     }
// })



export default router
