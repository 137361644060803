<template>
    <div class="act-container">
        <div class="act-main">
            <div class="act-header"></div>
            <div class="act-body">
                <router-link :to="{name:'company',query:{activityId:item.id}}" class="act-body-card"  v-for="(item,index) in response" :key="index">
                    <div class="act-body-card-left"><img style="height: 100%;width: 100%" :src="item.activityImages" alt=""/></div>
                    <div class="act-body-card-right">
                        <div>
                            <div class="act-body-card-right-title">{{item.name}}</div>
                            <div class="act-body-card-right-second">
                                <p class="act-body-card-right-state split">{{item.status}}</p>
                                <p class="act-body-card-right-address split">{{item.address}}</p>
                                <div class="act-body-card-right-time"><span>{{item.startTime}} 至 {{item.endTime}}</span></div>
                            </div>
                        </div>
                        <div class="act-section-left">
                            <span></span>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="act-footer">
                <div class="block">
                    <el-pagination
                        @current-change="handleCurrentPage"
                        align='center'
                        layout="prev, pager, next"
                        :sizes="pageSize"
                        :current-page="pageNum"
                        :total="totalCount">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as api from '@/api/api'
    export default {
        name: "activity",
        data() {
            return {
                pageNum:1,
                pageSize:100,
                response:[],
                status:'',
                totalCount: ""
            }
        },
        methods: {
            findAll(){
                let params = {
                    pageNum:this.pageNum,
                    pageSize:this.pageSize
                }
                api.findAllActByPage(params).then(res => {
                    console.log(res)
                    //this.response = res
                    this.response = res.filter(item => (item.id<20 || item.id>39) )
                })
            },
            getTotalCount() {
                let params = {
                    pageNum:this.pageNum,
                    pageSize:this.pageSize
                }
                api.findAllActByPage(params).then(res => {
                    let List = res.filter(item => (item.id<20 || item.id>39) )
                    this.totalCount = List.length
                })
            },
            handleCurrentPage(val) {
                this.pageNum = val
                this.findAll()
            }
        },
        created() {
            this.getTotalCount()
        },
        mounted() {
            this.findAll()
        }
    }
</script>

<style scoped>
    @media screen and (min-width: 991px){
        .act-container {
            margin: 0;
            padding: 0;
            width: 100vw;
            background-size:100% 100%;
            background-image: url("../assets/images/activity-back.png");
            background-repeat: no-repeat;
        }
        .act-main {
            width: 60vw;
        }
        .act-header {

        }
        .act-body {
            width: 60vw;
            margin: 0 0 0 20vw;
            padding: 5vh 0 0 0;
        }
        .act-body-card {
            width: 100%;
            height: 20vh;
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            background: #fff;
            border: 1px solid #f2f2f2;
            border-radius: 4px;
            margin: 0 0 5vh 0;
        }
        /*:hover 悬浮效果。box-shadow阴影效果*/
        .act-body-card:hover {
            box-shadow: 10px 10px 10px -4px rgba(0, 0, 0, .3);
        }
        .act-body-card-left {
            width: 20vw;
            height: 100%;
        }
        .act-section-left span {
            background: url('../assets/images/rightArrow.png') 0 no-repeat;
            display: block;
            width: 24px;
            height: 24px;
            margin: 42px auto;
        }
        .act-body-card-right {
            width: 40vw;
            padding: 4rem 0 0 4rem;
            display: flex;
            justify-content: space-between;
        }
        .act-body-card-right-title {
            font-size: 18px;
            color: #212e40;
            font-weight: 500;
            line-height: 26px;
        }
        .act-body-card-right-second {
            margin: 2vh 0 0 0 ;
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            color: #5a677a;
        }
        .split {
            margin: 0 2rem 0 0;
        }
        .act-body-card-right-state:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url("../assets/images/activity-state.png") 0 no-repeat;
            position: relative;
            left: -2px;
            top: 2px;
            background-size: contain;
        }
        .act-body-card-right-state {
            color: #4075ff;
            background: #ecf1ff;
            border: 1px solid #cfdcff;
            border-radius: 20px;
            padding: 0 5px;
        }
        .act-body-card-right-address:before {
            content: "";
            padding-left: 18px;
            font-size: 14px;
            color: #5a677a;
            background: url("../assets/images/activity-address.png") 0 no-repeat;
            word-break: break-all;
            word-wrap: break-word;
            max-width: 410px;
        }
        .act-body-card-right-time:before {
            content: "";
            color: #5a677a;
            padding-left: 18px;
            background: url("../assets/images/activity-time.png") 0 no-repeat;
        }

        .act-footer {
            width: 100vw;
        }
        .block {
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }
    /*小设备*/
    @media screen and (max-width: 991px){
        .act-container {
            margin: 0;
            padding: 0;
            width: 100vw;
            background-size:100% 100%;
            background-image: url("../assets/images/activity-back.png");
            background-repeat: no-repeat;
        }
        .act-main {
            width: 90vw;
            margin: 0 auto;
        }
        .act-body {
            width: 100%;
        }
        .act-body-card {
            width: 100%;
            height: auto;
            display: flex;
            /*justify-content: flex-start;*/
            flex-direction: column;
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            background: #fff;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            margin: 2vh 0;
            padding: 5px;
        }
        .act-body-card-right-title {
            font-size: 18px;
            color: #212e40;
            font-weight: 500;
            line-height: 26px;
            padding: 0 0 0 10px;
        }
        .act-body-card-right-second {
            font-size: 14px;
            color: #5a677a;
            padding: 0 0 0 10px;
        }
        .act-body-card-right-state:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url("../assets/images/activity-state.png") 0 no-repeat;
            position: relative;
            left: -2px;
            top: 2px;
            background-size: contain;
        }
        .act-body-card-right-address:before {
            content: "";
            padding-left: 18px;
            font-size: 14px;
            color: #5a677a;
            background: url("../assets/images/activity-address.png") 0 no-repeat;
            word-break: break-all;
            word-wrap: break-word;
            max-width: 410px;
        }
        .act-body-card-right-time:before {
            content: "";
            color: #5a677a;
            padding-left: 18px;
            background: url("../assets/images/activity-time.png") 0 no-repeat;
        }
        .act-footer {
            width: 90%;
            margin: 0 auto;
        }
    }
</style>
